import React, { useState, useEffect, useRef } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import { Button, Popper, Stack, Typography } from "@mui/material";
import format from "date-fns/format";
import {
  addDays,
  isSameDay,
  startOfDay,
  endOfDay,
  differenceInCalendarDays,
  startOfYear,
  endOfYear,
  addYears,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addMonths,
} from "date-fns";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useImmer } from "use-immer";

export const DashBoardDateRangePicker = (props) => {
  const {
    onChangeDate,
    range,
    showRange,
    isOpen,
    handleIsOpen,
    handleDateChangeApply,
    isList,
    className,
    hasFutureDate = false,
    label = null,
  } = props;
  // get the target element to toggle
  const refOne = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
    return () => {
      document.addEventListener("keydown", hideOnEscape, true);
      document.addEventListener("click", hideOnClickOutside, true);
    };
  }, [refOne]);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      handleIsOpen(false);
      setAnchorEl(e.currentTarget);
    }
  };

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      handleIsOpen(false);
      setAnchorEl(e.currentTarget);
    }
  };
  const defineds = {
    startOfWeek: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endOfWeek: endOfWeek(new Date(), { weekStartsOn: 1 }),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  };
  const defaultInputRanges = [
    {
      label: "days up to today",
      range(value) {
        return {
          startDate: addDays(
            defineds.startOfToday,
            (Math.max(Number(value), 1) - 1) * -1
          ),
          endDate: defineds.endOfToday,
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.endDate, defineds.endOfToday)) return "-";
        if (!range.startDate) return "∞";
        return (
          differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1
        );
      },
    },
  ];
  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };

  function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  }

  const defaultStaticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: hasFutureDate
        ? defineds.endOfWeek
        : defineds.endOfToday,
      }),
    },
    {
      label: "Last Week",
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: hasFutureDate
          ? defineds.endOfMonth
          : defineds.endOfToday,
      }),
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    },
  ]);

  const inputStyle = isList
    ? {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "10px",
      border: "1px solid #E6E6E6",
      height: "48px",
      borderRadius: "8px",
      padding: "8px 12px",
    }
    : {
      flexDirection: "row",
      gap: "8px",
      padding: "8px 12px",
      backgroundColor: "rgba(21, 96, 212, 0.08)",
      borderRadius: "8px",
    };

  const textStyle = isList
    ? {
      fontSize: "0.9rem",
      color: "#65748B",
      fontWeight: 400,
      whiteSpace: "nowrap",
    }
    : {
      color: "#1560D4",
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: 1.57,
    };

  return (
    <div className={`calendarWrap ${className}`}>
      <Stack
        sx={inputStyle}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          handleIsOpen(!isOpen);
        }}
      >
        {!isList && (
          <CalendarTodayIcon
            sx={{
              color: "#1560D4",
              width: "24px",
              height: "24px",
            }}
          />
        )}

        <Typography sx={textStyle} whiteSpace={"nowrap"}>
          {showRange.length > 0
            ? `${format(showRange[0].startDate, "MMM dd, yyyy")} - ${format(
              showRange[0].endDate,
              "MMM dd, yyyy"
            )}`
            : label ? label : "Select Date"}
        </Typography>
        {isList && (
          <CalendarTodayIcon
            sx={{
              color: "rgba(0, 0, 0, 0.54)",
              width: "24px",
              height: "24px",
            }}
          />
        )}
      </Stack>
      <Popper
        className="calendarElement"
        ref={refOne}
        open={isOpen}
        anchorEl={anchorEl}
        sx={{
          zIndex: "9999",
          border: "none",
          borderRadius: "8px",
          boxShadow: "0px 1px 45px rgb(0 0 0 / 16%)",
          "&.rdrCalendarWrapper": {
            borderRadius: "8px",
            "&.rdrMonthAndYearWrapper": {
              paddingTop: 0,
            },
          },
        }}
        placement="auto"
      >
        <DateRangePicker
          onChange={(item) => onChangeDate([item.selection])}
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
          ranges={range}
          months={isList ? 1 : 2}
          direction="horizontal"
          inputRanges={defaultInputRanges}
          // renderStaticRangeLabel={false}
          staticRanges={defaultStaticRanges}
          showDateDisplay={false}
          weekStartsOn={1}
          color="#1560D4"
          maxDate={!hasFutureDate ? new Date() : undefined}
        />
        <Button
          variant="contained"
          size="small"
          onClick={() => handleDateChangeApply()}
          sx={{
            position: "absolute",
            bottom: "10px",
            left: "18px",
          }}
        >
          Apply
        </Button>
      </Popper>
    </div>
  );
};
