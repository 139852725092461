import { Stack, Typography, Box, Divider, Chip } from "@mui/material";
import React from "react";
import "react-quill/dist/quill.snow.css";
import SimpleReactValidator from "simple-react-validator";
import { ContentLayout, CustomDetailsList, HeaderBreadcrumbs, Loader } from "../../../shared";
import { useNavigate, useParams } from "react-router";
import { useBirthdayCoupons } from "../hooks";
import parse from "html-react-parser";


export const BirthdayCouponsDetails = () => {
  const { id } = useParams();
  const { state, globalData } = useBirthdayCoupons({ isDetails: id });

  const { details, isLoading } = state.BirthdayCouponsDetails;
  const conditionType = [
    { label: "AND", value: 1 },
    { label: "OR", value: 2 },
  ];
  const column = [
    {
      title: "Coupon Name",
      accessor: "name",
    },
    {
      title: "Label",
      accessor: "label",
    },
    {
      title: "Coupon Code ",
      accessor: "coupon_code",
    },
    {
      title: "Description",
      accessor: (row) => {
        return <div>{row.description !== null && parse(row.description)}</div>;
      },
    },
    {
      title: "Images",
      accessor: (row) => {
        return (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
                borderRadius: 1,
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "200px",
                  maxHeight: "200px",
                  objectFit: "cover",
                }}
                alt="img"
                src={row.mobile_image}
              />
            </Box>
            <Box
              sx={{
                width: "400px",
                height: "200px",
                borderRadius: 1,
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "200px",
                  maxHeight: "200px",
                  objectFit: "cover",
                }}
                alt="img"
                src={row.desktop_image}
              />
            </Box>
          </Stack>
        );
      },
    },
    {
      title: "Discount Type & Amount",
      header: () => {
        return (
          <>
            {" "}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="83px"
              p="32px 24px"
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Action Type
              </Typography>
            </Stack>
            <Divider />
          </>
        );
      },
      accessor: (row) => {
        return (
          <Stack direction="row" spacing={1}>
            <Stack>
              {
                globalData?.coupons?.discount_type?.find(
                  (item) => item.value === row.discount_type
                )?.label
              }
            </Stack>
            <Stack>
              <Typography color={"#C07302"}>
                - {row.discount_amount}
                {row.discount_type === 1 ? "$" : "%"}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },

    {
      title: "Rule Type",
      header: () => {
        return (
          <>
            {" "}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="83px"
              p="32px 24px"
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Rules
              </Typography>
            </Stack>
            <Divider />
          </>
        );
      },

      accessor: (row) => {
        return conditionType.find((item) => item.value === row.condition_type)
          ?.label;
      },
    },
    {
      title: "Condition",
      accessor: (row) => {
        const rules = row.conditions;
        return (
          <div>
            <>
              <Box>
                {rules?.slice(0, 2).map((data) => {
                  return (
                    <Chip
                      sx={{ mr: 1, borderRadius: "8px" }}
                      label={data.name}
                      variant="filled"
                      size="small"
                    />
                  );
                })}
                {rules?.length > 2 && (
                  <Chip
                    sx={{ borderRadius: "8px" }}
                    label={`+${rules?.length - rules?.slice(0, 2)?.length}`}
                    variant="filled"
                    size="small"
                  />
                )}
              </Box>
            </>
          </div>
        );
      },
    },
    {
      title: "Maximum Product Qty",
      accessor: "applicable_max_product_qty",
      hidden: (val) => {
        return !val.conditions.some(v => v.scope == 3)
      }
    },
  ];

  return (
    <>
      <ContentLayout
        title={details.name || ""}
        breadcrumbs={
          <HeaderBreadcrumbs
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Birthday Coupons", href: "/birthday-coupons" },
              { name: "Details", },
            ]}
          />
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <CustomDetailsList
              columns={column}
              data={details}
              title="Coupon Details"
            />
          </>
        )}
      </ContentLayout>
    </>
  );
};
