import * as React from "react";
import { Box, Button, Stack } from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import { Loader } from "../../../shared";

export const DoordashSetupDialog = ({
  onChange,
  state,
  handleSetupDoordash,
}) => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  const {
    requestor_email,
    merchant_decision_maker_email,
    doordash_store_id,
    partner_store_name,
    requestor_first_name,
    requestor_last_name,
    address_city,
    address_state,
    address_zip,
    address_line_1,
  } = state.storeDetails;

  const onSubmit = () => {
    if (validator.current.allValid()) {
      handleSetupDoordash();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <Stack justifyContent="space-between" height="100%" gap={2} mt="32px">
      {validator.current.purgeFields()}
      {state?.isBusy ? (
        <Loader />
      ) : (
        <>
          <InputControl
            name="requestor_email"
            label="Requestor Email"
            required
            onChange={onChange}
            value={requestor_email}
            error={validator.current.message(
              "Requestor Email",
              requestor_email,
              "required"
            )}
            helperText={validator.current.message(
              "Requestor Email",
              requestor_email,
              "required"
            )}
          />
          <InputControl
            name="merchant_decision_maker_email"
            label="Merchant Decision Maker Email"
            required
            onChange={onChange}
            value={merchant_decision_maker_email}
            error={validator.current.message(
              "Merchant Decision Maker Email",
              merchant_decision_maker_email,
              "required"
            )}
            helperText={validator.current.message(
              "Merchant Decision Maker Email",
              merchant_decision_maker_email,
              "required"
            )}
          />
          <InputControl
            name="doordash_store_id"
            label="Doordash Store Id"
            sx={{
              width: "100%",
              bgcolor: "#fff",
            }}
            onChange={onChange}
            value={doordash_store_id}
          />
          <InputControl
            name="partner_store_name"
            label="Doordash Store Name"
            value={partner_store_name}
            onChange={onChange}
          />
          <InputControl
            name="requestor_first_name"
            label="Requestor First Name"
            value={requestor_first_name}
            onChange={onChange}
          />
          <InputControl
            name="requestor_last_name"
            label="Requestor Last Name"
            value={requestor_last_name}
            onChange={onChange}
          />

          <InputControl
            name="address_city"
            label="Address City"
            value={address_city}
            onChange={onChange}
            error={
              !doordash_store_id &&
              validator.current.message(
                "Address City",
                address_city,
                "required"
              )
            }
            helperText={
              !doordash_store_id &&
              validator.current.message(
                "Address City",
                address_city,
                "required"
              )
            }
          />
          <InputControl
            name="address_state"
            label="Address State"
            value={address_state}
            onChange={onChange}
            error={
              !doordash_store_id &&
              validator.current.message(
                "Address State",
                address_state,
                "required"
              )
            }
            helperText={
              !doordash_store_id &&
              validator.current.message(
                "Address State",
                address_state,
                "required"
              )
            }
          />
          <InputControl
            name="address_zip"
            label="Address Zip"
            value={address_zip}
            onChange={onChange}
            error={
              !doordash_store_id &&
              validator.current.message("Address Zip", address_zip, "required")
            }
            helperText={
              !doordash_store_id &&
              validator.current.message("Address Zip", address_zip, "required")
            }
          />
          <InputControl
            name="address_line_1"
            label="Address Line 1"
            value={address_line_1}
            onChange={onChange}
            error={
              !doordash_store_id &&
              validator.current.message(
                "Address Line 1",
                address_line_1,
                "required"
              )
            }
            helperText={
              !doordash_store_id &&
              validator.current.message(
                "Address Line 1",
                address_line_1,
                "required"
              )
            }
          />
          <Box
            sx={{
              position: "sticky",
              bottom: -1,
              backgroundColor: "#FFFFFF",
              boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Button
              onClick={onSubmit}
              sx={{ height: "40px", alignSelf: "end" }}
              variant="contained"
              fullWidth
              disabled={state?.isSaveButtonBusy}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </Stack>
  );
};
