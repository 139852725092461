/* eslint-disable no-throw-literal */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { errorMessage, successMessage } from "../../../utils";
import {
  assignUserToPosRoleApi,
  deleteMemberFromPosRoleApi,
  deletePosRoleApi,
  getPosRoleDetailsByIdApi,
  getUsersList,
} from "../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCreateOrEditPosRoles } from "./useCreateOrEditPosRoles";
import { AppContext } from "../../../store";
import { useImmer } from "use-immer";

export const usePosRoleDetails = () => {
  const [state, setState] = useImmer({
    roleDetails: {
      isLoading: true,
      details: {},
      permission_id: [],
    },
    isDeleteDialogOpen: false,
    // Members list
    open: false,
    membersList: [],
    allUsers: [],
    isLoading: false,
    filteredMembersList: [],
    filterStatus: 2,
    userSearchText: null,
    filteredUsers: [],
    selectedUsers: [],
    memberDeleteModal: false,
    selectedMember: null,
    isMemberTableLoading: false,
  });

  const { appState } = React.useContext(AppContext);

  const createOrEditPosRoleState = useCreateOrEditPosRoles();

  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");

  const { roleId } = useParams();
  const navigate = useNavigate();
  const POS_PERMISSIONS = createOrEditPosRoleState.state.permissions;

  const breadcrumbLinks = [
    { name: "Dashboard", href: "/" },
    {
      name: "Roles & Permissions",
      href: isStoreAdmin
        ? "/store-admin/roles-and-permissions"
        : "/roles-and-permissions",
    },
    state.roleDetails.details?.display_name
      ? {
        name: state.roleDetails.details?.display_name,
        href: "/",
      }
      : undefined,
  ].filter(Boolean);

  // ================================================================= API SECTION ====================================================
  /**
   * Fetches the details of a POS role by its ID and updates the application state.
   *
   * This function retrieves the role details, including members and permissions, from the API.
   * It updates the state with the fetched details and handles errors by displaying an error message.
   *
   * @async
   * @function getPosRoleDetails
   * @param {string} roleId - The ID of the role to fetch details for.
   * @returns {Promise<void>} A promise that resolves when the details have been fetched and the state updated.
   */
  const getPosRoleDetails = async (roleId) => {
    triggerPosRoleDetailsLoading(true);
    try {
      const response = await getPosRoleDetailsByIdApi(roleId);
      const { success, message, data } = response;
      if (success) {
        const { members, permissions, ...details } = data;
        setState((draft) => {
          draft.roleDetails.details = details;
          draft.membersList = members;
          draft.filteredMembersList = members;
          draft.roleDetails.permission_id = permissions.map((permission) => {
            return {
              ...permission,
              [permission.id]: Boolean(permission.manager_passcode),
            };
          });
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message = error.response?.data?.message;
      errorMessage(message);
    } finally {
      triggerPosRoleDetailsLoading(false);
    }
  };

  /**
   * Deletes a POS role by its ID and navigates to the roles and permissions page.
   *
   * This function sends a request to delete the specified POS role. If the deletion is successful,
   * it displays a success message and redirects the user to the roles and permissions page.
   * In case of failure, it shows an error message.
   *
   * @async
   * @function deletePosRole
   * @param {string} roleId - The ID of the role to be deleted.
   * @returns {Promise<void>} A promise that resolves when the deletion process is complete.
   */
  const deletePosRole = async (roleId) => {
    try {
      const response = await deletePosRoleApi(roleId);

      const { success, message } = response;
      if (success) {
        successMessage(message);
        navigate("/roles-and-permissions", { replace: true });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error.response?.data?.message ??
        "Failed to fetch POS roles. Please try again later.";
      errorMessage(message);
    }
  };

  /**
   * Fetches the list of users and updates the application state.
   *
   * This function retrieves the users list from the API and updates the state with the fetched data.
   * If the fetch is unsuccessful, it throws an error with a corresponding message.
   *
   * @async
   * @function fetchUsersList
   * @returns {Promise<void>} A promise that resolves when the users list has been fetched and the state updated.
   */
  const fetchUsersList = async () => {
    try {
      const response = await getUsersList();
      const { success, data, message } = response;
      if (success) {
        setState((draft) => {
          draft.allUsers = data;
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ?? "Unable to fetch users list";
      errorMessage(message);
    }
  };

  /**
   * Assigns a user to a specified POS role.
   *
   * This function sends a request to assign a user to the specified role. If the assignment is successful,
   * it displays a success message, handles the user dialog, and fetches the updated role details.
   * In case of failure, it shows an error message.
   *
   * @async
   * @function assignUserToPosRole
   * @param {Object} params - The parameters for assigning a user to a role.
   * @param {string} params.roleId - The ID of the role to which the user is being assigned.
   * @param {...*} payload - Additional payload data to be sent with the assignment request.
   * @returns {Promise<void>} A promise that resolves when the user assignment process is complete.
   */
  const assignUserToPosRole = async ({ roleId, ...payload }) => {
    try {
      const response = await assignUserToPosRoleApi(payload, roleId);
      const { success, message } = response;
      if (success) {
        successMessage(message);
        handleUserDialog();
        await getPosRoleDetails(roleId);
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ?? "Unable to assign role to members";
      errorMessage(message);
    }
  };

  /**
   * Un-assigns a member from a specified POS role.
   *
   * This function sends a request to remove a member from a role. If the un-assignment is successful,
   * it updates the state by filtering out the unassigned member from the member lists and displays a success message.
   * In case of failure, it shows an error message. The loading state is managed during the process.
   *
   * @async
   * @function unassignMemberRole
   * @param {Object} params - The parameters for un-assigning a member from a role.
   * @param {string} params.role_id - The ID of the role from which the member is being unassigned.
   * @param {Object} payload - Additional payload data to be sent with the un-assignment request.
   * @param {Array<string>} payload.user_id - An array containing the IDs of the users to be unassigned.
   * @returns {Promise<void>} A promise that resolves when the un-assignment process is complete.
   */
  const unassignMemberRole = async ({ role_id, ...payload }) => {
    triggerMemberTableLoading(true);
    try {
      const response = await deleteMemberFromPosRoleApi(payload, role_id);
      const { success, message } = response;
      if (success) {
        const unAssignedMemberId = payload.user_id[0];
        successMessage(message);

        setState((draft) => {
          draft.membersList = draft.membersList.filter(
            (item) => item.id !== unAssignedMemberId
          );
          draft.filteredMembersList = draft.filteredMembersList.filter(
            (item) => item.id !== unAssignedMemberId
          );
        });
        handleMemberDeleteModal(false, null);
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error.response?.data?.message ??
        "Unable to un assign the role from the member";
      errorMessage(message);
    } finally {
      triggerMemberTableLoading(false);
    }
  };

  // ============================================================== UTIL SECTION FUNCTION =============================================
  /**
   * Toggles the loading state for the member table.
   *
   * This function updates the loading state of the member table in the application state.
   *
   * @function triggerMemberTableLoading
   * @param {boolean} status - The loading status to set (true for loading, false for not loading).
   * @returns {void}
   */
  const triggerMemberTableLoading = (status) => {
    setState((draft) => {
      draft.isMemberTableLoading = status;
    });
  };

  /**
   * Toggles the loading state for the POS role details.
   *
   * This function updates the loading state of the POS role details in the application state.
   *
   * @function triggerPosRoleDetailsLoading
   * @param {boolean} status - The loading status to set (true for loading, false for not loading).
   * @returns {void}
   */
  const triggerPosRoleDetailsLoading = (status) => {
    setState((draft) => {
      draft.roleDetails.isLoading = status;
    });
  };

  /**
   * Checks whether specific permissions are granted based on the provided type.
   *
   * This function evaluates the checked state of permissions for various contexts:
   * - All permissions
   * - Module-specific permissions
   * - Manager passcode permissions
   * It returns a boolean indicating whether the specified permissions are checked.
   *
   * @function isPermissionChecked
   * @param {Object} params - The parameters for checking permissions.
   * @param {string} [params.type="UNIQUE_PERMISSION"] - The type of permission check to perform.
   *   Possible values include:
   *   - "ALL_PERMISSION": Check if all permissions are granted.
   *   - "MODULE_PERMISSION": Check if all permissions for a specific module are granted.
   *   - "ALL_MANAGER_PASSCODE": Check if all manager passcode are checked.
   *   - "MANAGER_PASSCODE_MODULE_PERMISSION": Check for manager passcode within a specific module.
   *   - "MANAGER_PASSCODE_UNIQUE_PERMISSION": Check for a specific unique permission's manager passcode.
   * @param {Object} [params.row=null] - The row object representing the module or permission context.
   * @returns {boolean} True if the specified permissions are checked, false otherwise.
   */
  const isPermissionChecked = ({ type = "UNIQUE_PERMISSION", row = null }) => {
    switch (type) {
      case "ALL_PERMISSION": {
        const { permission_id } = state.roleDetails;
        const permissions = POS_PERMISSIONS.allList.flatMap((module) =>
          module.permissions.map((permission) => permission)
        );
        return permission_id.length === permissions.length;
      }
      case "MODULE_PERMISSION": {
        const module = row.original;
        const { permission_id } = state.roleDetails;

        const checkedModulePermissions = permission_id.filter(
          (permission) => permission.group === module.name
        );

        return checkedModulePermissions.length === module.permissions.length;
      }
      case "ALL_MANAGER_PASSCODE": {
        const { permission_id } = state.roleDetails;

        if (permission_id.length === 0) return false;

        const hasUnchecked = permission_id.some((permission) => {
          return permission[permission.id] === false;
        });

        if (hasUnchecked) {
          return false;
        }

        const allCheckedPermissions = permission_id.filter((permission) => {
          return permission[permission.id] !== false;
        });

        return allCheckedPermissions.length === permission_id.length;
      }
      case "MANAGER_PASSCODE_MODULE_PERMISSION": {
        const module = row.original;
        const { permission_id } = state.roleDetails;

        if (permission_id.length === 0) return false;

        const modulePermissions = permission_id.filter(
          (permission) => permission.group === module.name
        );

        const hasUnchecked = modulePermissions.some((permission) => {
          return permission[permission.id] === false;
        });

        if (hasUnchecked) {
          return false;
        }

        const allCheckedPermissions = modulePermissions.filter((permission) => {
          return permission[permission.id] !== false;
        });

        return allCheckedPermissions.length === module.permissions.length;
      }
      case "MANAGER_PASSCODE_UNIQUE_PERMISSION": {
        const module = row.original;
        const { permission_id } = state.roleDetails;
        const checked = permission_id.some(
          (permission) => permission[module.id] === true
        );
        return checked;
      }
      default: {
        const module = row.original;
        const { permission_id } = state.roleDetails;
        const checked = permission_id.some(
          (permission) => permission.id === module.id
        );
        return checked;
      }
    }
  };

  /**
   * Determines if a checkbox is in an indeterminate state based on the checked status of permissions.
   *
   * The function evaluates whether the checkbox should be visually represented as indeterminate
   * based on the state of checked permissions across various contexts:
   * - Header level
   * - Manager passcode header and cell
   * - Regular cell level
   *
   * @function isIndeterminate
   * @param {Object} params - The parameters for checking indeterminate state.
   * @param {boolean} params.allChecked - Indicates if all permissions are checked.
   * @param {Object} params.row - The row object representing the current context (e.g., module or permission).
   * @param {string} [params.type="CELL"] - The type of indeterminate check to perform.
   *   Possible values include:
   *   - "HEAD": Check for the header level.
   *   - "MANAGER_PASSCODE_HEAD": Check for manager passcode at the header level.
   *   - "MANAGER_PASSCODE_CELL": Check for manager passcode at the cell level.
   * @returns {boolean} True if the checkbox is indeterminate, false otherwise.
   */
  const isIndeterminate = ({ allChecked, row, type = "CELL" }) => {
    switch (type) {
      case "HEAD": {
        const { permission_id } = state.roleDetails;
        const indeterminate = !allChecked && permission_id.length !== 0;
        return indeterminate;
      }
      case "MANAGER_PASSCODE_HEAD": {
        const { permission_id } = state.roleDetails;
        const checkedPermissions = permission_id.filter(
          (permission) => permission[permission.id] === true
        );
        const indeterminate = !allChecked && checkedPermissions.length !== 0;
        return indeterminate;
      }
      case "MANAGER_PASSCODE_CELL": {
        const isModuleLevel = row.depth === 0 ? true : false;

        if (isModuleLevel) {
          const module = row.original;
          const { permission_id } = state.roleDetails;

          const modulePermissions = permission_id.filter(
            (permission) => permission.group === module.name
          );
          const checkedPermissions = modulePermissions.filter(
            (permission) => permission[permission.id] === true
          );

          const indeterminate =
            !allChecked &&
            checkedPermissions.length !== 0 &&
            checkedPermissions.length !== modulePermissions.length;
          return indeterminate;
        }

        return isModuleLevel;
      }
      default: {
        const isModuleLevel = row.depth === 0 ? true : false;

        if (isModuleLevel) {
          const module = row.original;
          const { permission_id } = state.roleDetails;

          if (permission_id.length === 0) return false;

          const modulePermissions = permission_id.filter(
            (permission) => permission.group === module.name
          );
          const indeterminate =
            !allChecked &&
            modulePermissions.length > 0 &&
            row.originalSubRows.length > modulePermissions.length;

          return indeterminate;
        }

        return isModuleLevel;
      }
    }
  };

  /**
   * Toggles the visibility of the delete confirmation dialog.
   *
   * This function updates the state to open or close the delete dialog.
   *
   * @function handleDeleteDialog
   */
  const handleDeleteDialog = () => {
    setState((draft) => {
      draft.isDeleteDialogOpen = !draft.isDeleteDialogOpen;
    });
  };

  /**
   * Handles the confirmation of a role deletion.
   *
   * This function first toggles the delete dialog state and then
   * calls the deletePosRole function with the specified roleId.
   *
   * @async
   * @function handleConfirmDeleteRole
   */
  const handleConfirmDeleteRole = async () => {
    handleDeleteDialog();
    await deletePosRole(roleId);
  };

  /**
   * Toggles the user selection dialog visibility and resets selected users.
   *
   * If the dialog is currently open, it clears the selected users and user search text.
   *
   * @function handleUserDialog
   */
  const handleUserDialog = () => {
    setState((draft) => {
      if (draft.open) {
        draft.selectedUsers = [];
        draft.userSearchText = "";
      }
      draft.open = !draft.open;
    });
  };

  /**
   * Filters the list of users based on the search text.
   *
   * The function updates the state with filtered users based on the specified text.
   * It ensures that already assigned members are not included in the filtered list.
   *
   * @function handleFilterUsers
   * @param {string} type - The type of filter operation ("initial" or "search").
   * @param {string} text - The search text to filter users.
   */
  const handleFilterUsers = (type, text) => {
    const alreadyMembers = state.membersList.map((val) => val.id);

    const filteredUsersList = state.allUsers.filter(
      (val) => !alreadyMembers.includes(val.id)
    );
    if (type === "initial") {
      setState((draft) => {
        draft.filteredUsers = filteredUsersList;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.userSearchText = text;
        draft.filteredUsers = filteredUsersList.filter((user) => {
          return ["name", "email"].some((item) => {
            return user[item].toString().toLowerCase().indexOf(text) !== -1;
          });
        });
      });
    }
  };

  /**
   * Checks if a user is selected.
   *
   * @function hasSelectedUser
   * @param {string} userId - The ID of the user to check.
   * @returns {boolean} True if the user is selected, false otherwise.
   */
  const hasSelectedUser = (userId) => state.selectedUsers.includes(userId);

  /**
   * Handles the selection or deselection of a user.
   *
   * This function updates the state to either add or remove a user ID
   * from the selected users based on the current selection status.
   *
   * @function handleUserSelection
   * @param {string} userId - The ID of the user to select or deselect.
   */
  const handleUserSelection = (userId) => {
    const isSelected = state.selectedUsers.includes(userId);

    setState((draft) => {
      draft.selectedUsers = isSelected
        ? draft.selectedUsers.filter((id) => id !== userId)
        : [...draft.selectedUsers, userId];
    });
  };

  /**
   * Handles the assignment of selected users to a specific role.
   *
   * This function creates a payload with the role ID and selected user IDs,
   * then calls the assignUserToPosRole function to assign the users.
   *
   * @async
   * @function handleAssignUser
   */
  const handleAssignUser = async () => {
    const payload = {
      roleId,
      user_id: state.selectedUsers,
    };
    await assignUserToPosRole(payload);
  };

  /**
   * Handles the un-assignment of a user from a specific role.
   *
   * This function creates a payload with the role ID and the selected member's user ID,
   * toggles the member delete modal visibility, and then calls the unassignMemberRole function.
   *
   * @async
   * @function handleUnassignUser
   */
  const handleUnassignUser = async () => {
    const payload = {
      role_id: roleId,
      user_id: [state.selectedMember],
    };

    handleMemberDeleteModal();
    await unassignMemberRole(payload);
  };

  /**
   * Toggles the visibility of the member delete modal and sets the selected member ID.
   *
   * @function handleMemberDeleteModal
   * @param {boolean} data - The state to set for the modal visibility.
   * @param {string} id - The ID of the member to be deleted.
   */
  const handleMemberDeleteModal = (data, id) => {
    setState((draft) => {
      draft.memberDeleteModal = data;
      draft.selectedMember = id;
    });
  };

  /**
   * Filters the members list based on their status.
   *
   * This function updates the state with the selected status and filters the
   * members list accordingly. If the status is 1 or 0, it filters the members
   * list based on the member's status.
   *
   * @function handleStatusFilter
   * @param {number} status - The status to filter members by.
   */
  const handleStatusFilter = (status) => {
    setState((draft) => {
      draft.filterStatus = status;
      draft.filteredMembersList =
        status === 1 || status === 0
          ? state.membersList.filter((val) => val.status === status)
          : draft.membersList;
    });
  };

  /**
   * Resets the filters to show all members.
   *
   * This function calls handleStatusFilter with a status of 2,
   * which presumably indicates "all" or "no filter".
   *
   * @function resetFilters
   */
  const resetFilters = () => handleStatusFilter(2);

  // ============================================================== EFFECT SECTION ====================================================
  // Fetches the details of a specific POS role when the component mounts.
  React.useEffect(() => {
    getPosRoleDetails(roleId);
  }, []);

  // Fetches the users list when the general tab in the app state changes to "2".
  React.useEffect(() => {
    if (appState?.currentTabs?.generalTab === "2") {
      fetchUsersList();
    }
  }, [appState?.currentTabs?.generalTab]);

  // Initializes the filtered users list based on the current users and members lists.
  React.useMemo(() => {
    handleFilterUsers("initial");
  }, [state.allUsers, state.membersList]);

  return {
    roleId,
    state,
    breadcrumbLinks,
    permissions: POS_PERMISSIONS,
    isPermissionChecked,
    isIndeterminate,
    handleDeleteDialog,
    handleConfirmDeleteRole,
    handleUserDialog,
    handleFilterUsers,
    hasSelectedUser,
    handleUserSelection,
    handleAssignUser,
    handleMemberDeleteModal,
    handleUnassignUser,
    handleStatusFilter,
    resetFilters,
  };
};
