import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { useTabs } from "../../../shared";
import { AuthContext } from "../../../store/AuthProvider";
import {
  getAllConditionList,
  getBirthdayCouponsDetailsById,
  getBirthdayCouponsList,
  PlayOrPauseAllBirthdayCoupon,
  updateBirthdayCoupons,
} from "../api/BirthdayCouponsApi";
import qs from "qs";
import { errorMessage, successMessage } from "../../../utils";
import { useDebouncedCallback } from "use-debounce";
import {
  playpauseCoupons,
  deleteCouponsById,
  getCouponsDetailsById,
} from "../../Coupons/api/CouponsApi";
import { TableStateContext } from "../../../store";

export const useBirthdayCoupons = ({ isList, isDetailsId, isDetails }) => {
  const { appState } = useContext(AppContext);
  const { auth } = useContext(AuthContext);
  const {
    globalData,
    currentTabs: { birthdayCoupons: currentTab },
  } = appState;
  const navigate = useNavigate();
  const { setCurentTab } = useTabs();

  const [state, setState] = useImmer({
    isLoading: true,
    initialLoad: true,
    isOpen: false,
    id: null,
    isSaveButtonBusy: false,
    birthdayCouponsList: [],
    httpStatusCodes: {
      details: "",
    },
    BirthdayCouponsDetails: {
      isOpen: false,
      id: null,
      isLoading: true,
      isSaveButton: false,
      ConditionList: [],
      desktop_image: null,
      mobile_image: null,
      details: {
        conditions: [],
      },
    },
    filterApplied: {
      isSearching: false,
      searchText: "",
      status: "",
    },
    pagination: {
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    sortBy: [],
    isInProductRule: false,
  });

  const PAGE_NAME = "birthdaycoupon";
  const {
    filterState: {
      params: { birthdaycoupon },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);

  const debounced = useDebouncedCallback((value) => {
    const { searchText, status } = state.filterApplied;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filterApplied },
    });
    getBirthdayCoupons({
      search: value,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);
  useEffect(() => {
    const { searchText, status } = birthdaycoupon.filterApplied;
    const { pagination, sortBy } = birthdaycoupon;
    if (isList) {
      setState((draft) => {
        draft.filterApplied = birthdaycoupon.filterApplied;
        draft.pagination = pagination;
        draft.sortBy = sortBy;
      });
      getBirthdayCoupons({
        search: searchText,
        status,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
      HandleBirthdayCouponsDetails();
    }
  }, [isList]);

  useEffect(() => {
    if (isDetails) {
      getBirthdayCouponsDetails(isDetails);
    }
  }, [isDetails]);

  useEffect(() => {
    if (isDetailsId && state.BirthdayCouponsDetails.isLoading) {
      HandleBirthdayCouponsDetails();
      getAllCondition();
    }
  }, [isList, isDetailsId, state.BirthdayCouponsDetails.isLoading]);

  const getBirthdayCoupons = async ({
    search,
    status,
    pagination,
    sortBy = [],
  }) => {
    let params = {};
    if (search) {
      params = { ...params, search: search };
    }
    if (status !== "") {
      params = { ...params, status: status };
    }

    if (sortBy.length > 0) {
      const sortByParams = sortBy.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };

    try {
      setState((draft) => {
        draft.isLoading = true;
        draft.initialLoad = false;
      });
      const res = await getBirthdayCouponsList(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isLoading = false;
          draft.birthdayCouponsList = res.data.coupons;
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const handleBirthdayCouponStatus = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });

      const res = await playpauseCoupons(id);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
          draft.birthdayCouponsList = [
            ...draft.birthdayCouponsList.map((v) => {
              return id === v.id ? { ...v, active: v.active === 2 ? 3 : 2 } : v;
            }),
          ];
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const handleAllBirthdayCouponStatus = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });

      const res = await PlayOrPauseAllBirthdayCoupon("1");
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
          draft.BirthdayCouponsDetails.details.active =
            draft.BirthdayCouponsDetails.details.active === 2 ? 3 : 2;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const deleteModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };

  const handleDeleteCoupons = async () => {
    try {
      const res = await deleteCouponsById(state.id);
      if (res.success) {
        successMessage("Coupon deleted successfully");
        setState((draft) => {
          draft.birthdayCouponsList = draft.birthdayCouponsList.filter(
            (item) => item.id !== state.id
          );
        });
        deleteModal();
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  const onChangeFilter = (e) => {
    const { searchText, status } = state.filterApplied;
    const { pagination, sortBy } = state;
    if (e.target.name === "status") {
      getBirthdayCoupons({
        search: searchText,
        status: e.target.value,
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
    } else {
      debounced(e.target.value);
    }

    setState((draft) => {
      draft.filterApplied[e?.target?.name] = e?.target?.value;
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: {
          ...state.filterApplied,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const resetFilters = () => {
    if (state.filterApplied.status || state.filterApplied.searchText) {
      getBirthdayCoupons({
        pagination: {
          pageIndex: 1,
          pageSize: state.pagination.pageSize,
        },
        sortBy: state.sortBy,
      });
      setState((draft) => {
        draft.filterApplied.status = "";
        draft.filterApplied.searchText = "";
        draft.filterApplied.isSearching = true;
      });
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialState.params.birthdaycoupon.filterApplied,
      });
    }
  };

  const HandleBirthdayCouponsDetails = async () => {
    try {
      const res = await getBirthdayCouponsDetailsById("1");
      if (res.success) {
        setState((draft) => {
          draft.BirthdayCouponsDetails.details = {
            ...res.data,
            conditions: !res.data.conditions ? [""] : res.data.conditions,
          };
          draft.BirthdayCouponsDetails.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.BirthdayCouponsDetails.isLoading = false;
        draft.httpStatusCodes.details = err.response.status;
      });
    }
  };

  const handleInputChange = (event, v) => {
    const name = event?.target.name;
    const value = event?.target.value;
    if (name === "mobile_image") {
      setState((draft) => {
        draft.BirthdayCouponsDetails.mobile_image = value[0];
        draft.BirthdayCouponsDetails.details.mobile_image = URL.createObjectURL(
          value[0]
        );
      });
    } else if (name === "desktop_image") {
      setState((draft) => {
        draft.BirthdayCouponsDetails.desktop_image = value[0];
        draft.BirthdayCouponsDetails.details.desktop_image =
          URL.createObjectURL(value[0]);
      });
    }
    if (name === "conditions") {
      setState((draft) => {
        draft.BirthdayCouponsDetails.details.conditions = [
          ...draft.BirthdayCouponsDetails.details.conditions.map((data, i) =>
            i === v ? value : data
          ),
        ];
      });
    } else if (name === "show_in_list") {
      setState((draft) => {
        draft.BirthdayCouponsDetails.details.show_in_list = v ? 1 : 0;
      });
    } else {
      setState((draft) => {
        draft.BirthdayCouponsDetails.details = {
          ...draft.BirthdayCouponsDetails.details,
          [name]: value,
        };
      });
    }
  };

  const onUpdateBirthdayCoupon = async () => {
    try {
      setState((draft) => {
        draft.BirthdayCouponsDetails.isSaveButton = true;
      });

      let formData = new FormData();
      const data = { ...state.BirthdayCouponsDetails.details, _method: "PUT" };
      delete data.mobile_image;
      delete data.desktop_image;
      delete data.conditions;
      Object.keys(data).map((key) => {
        formData.append(key, data[key]);
      });

      if (state.BirthdayCouponsDetails.details.conditions?.length > 0) {
        state.BirthdayCouponsDetails.details.conditions.map((v) => {
          v && formData.append("conditions[]", v);
        });
      }
      if (state.BirthdayCouponsDetails.mobile_image) {
        formData.append(
          "mobile_image",
          state.BirthdayCouponsDetails.mobile_image
        );
      }
      if (state.BirthdayCouponsDetails.desktop_image) {
        formData.append(
          "desktop_image",
          state.BirthdayCouponsDetails.desktop_image
        );
      }

      formData.append(`applicable_max_product_qty`, state.BirthdayCouponsDetails.details.applicable_max_product_qty || "")

      const res = await updateBirthdayCoupons(formData);
      if (res.success) {
        successMessage("Update Successfully");
        setState((draft) => {
          draft.BirthdayCouponsDetails.isSaveButton = false;
        });
        navigate(`/birthday-coupons`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.BirthdayCouponsDetails.isSaveButton = false;
      });
    }
  };

  const getAllCondition = async () => {
    try {
      const res = await getAllConditionList();
      if (res.success) {
        setState((draft) => {
          draft.BirthdayCouponsDetails.ConditionList = res.data
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleAddCondition = (type, index) => {
    if (type === "add") {
      setState((draft) => {
        draft.BirthdayCouponsDetails.details.conditions = draft
          .BirthdayCouponsDetails.details.conditions
          ? [
            ...new Set([
              ...draft.BirthdayCouponsDetails.details.conditions,
              "",
            ]),
          ]
          : [""];
      });
    } else {
      setState((draft) => {
        draft.BirthdayCouponsDetails.details.conditions = [
          ...draft.BirthdayCouponsDetails.details.conditions.filter(
            (data, i) => i !== index
          ),
        ];
      });
    }
  };

  const getBirthdayCouponsDetails = async (id) => {
    try {
      setState((draft) => {
        draft.BirthdayCouponsDetails.isLoading = true;
      });
      const res = await getCouponsDetailsById(id);
      if (res.success) {
        setState((draft) => {
          draft.BirthdayCouponsDetails.isLoading = false;
          draft.BirthdayCouponsDetails.details = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.BirthdayCouponsDetails.isLoading = false;
      });
    }
  };
  const gotoPage = (page) => {
    const { searchText, status } = state.filterApplied;
    const { sortBy } = state;
    getBirthdayCoupons({
      search: searchText,
      status,
      pagination: {
        pageIndex: page,
        pageSize: state.pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };
  const setPageSize = (e) => {
    const { searchText, status } = state.filterApplied;
    const { sortBy } = state;
    getBirthdayCoupons({
      search: searchText,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };
  const handleSort = (sortBy) => {
    const { searchText, status } = state.filterApplied;
    const { pagination } = state;
    getBirthdayCoupons({
      search: searchText,
      status,
      pagination,
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };
  const onSeachClear = () => {
    const { status } = state.filterApplied;
    const { pagination, sortBy } = state;
    getBirthdayCoupons({
      search: null,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    setState((draft) => {
      draft.filterApplied.searchText = "";
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filterApplied, searchText: "" } },
    });
  };


  useEffect(() => {
    setState((draft) => {
      draft.isInProductRule = checkIsProductCondition();
    });
  }, [state.BirthdayCouponsDetails.details.conditions, state.BirthdayCouponsDetails.ConditionList])

  const checkIsProductCondition = () => {
    let c = state.BirthdayCouponsDetails.details.conditions.map(Number);

    if (c.length === 0) {
      return false
    }

    return state.BirthdayCouponsDetails.ConditionList.some((v) => {
      return c.includes(v.id) && v.scope == 3
    })
  }

  return {
    state,
    currentTab,
    setCurentTab,
    globalData,
    handleBirthdayCouponStatus,
    deleteModal,
    handleDeleteCoupons,
    onChangeFilter,
    resetFilters,
    handleAllBirthdayCouponStatus,
    handleInputChange,
    onUpdateBirthdayCoupon,
    handleAddCondition,
    gotoPage,
    setPageSize,
    handleSort,
    onSeachClear,
  };
};
