import React from "react";
import {
  Checkbox,
  Chip,
  Collapse,
  FormHelperText,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputControl from "../../../shared/components/InputControl";
import { AsyncPaginate } from "react-select-async-paginate";
import { Box } from "@mui/system";

const CustomOption = (props) => {
  const { data, innerRef, innerProps, isSelected } = props;
  return (
    <Stack
      ref={innerRef}
      {...innerProps}
      sx={{
        flexDirection: "row",
        p: "7px 8px",
        gap: "2px",
        "&:hover": {
          backgroundColor: "#edf3fc",
        },
      }}
    >
      <Checkbox
        checked={isSelected}
        sx={{
          p: "8px",
        }}
      />
      <Stack
        sx={{
          gap: "2px",
        }}
      >
        <Typography variant="subtitle2" fontWeight={400} lineHeight="17px">
          {data.label}
        </Typography>
        <Typography color="#666666" variant="caption" lineHeight="15px">
          {data.email}, {data.phone}
        </Typography>
      </Stack>
    </Stack>
  );
};
export const ConditionItems = ({
  value,
  scope,
  onChange,
  index,
  validator,
  productOptions,
  handleGetCustomers,
  selectedCustomersInOtherIndex,
  onDeleteProducts,
  onDeleteCustomerSelect,
}) => {
  const { rule_type } = value;
  const isValueValid = validator.current.message(
    "value",
    value.value,
    "required"
  );

  const [open, setOpen] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const handleClick = (id) => {
    setOpen(id === open ? null : id);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      minHeight: "55px",
      borderColor: isValueValid ? "#C60808" : "#E6E8F0",
      boxShadow: null,
      "&:hover": {
        borderColor: isValueValid ? "#C60808" : "#E6E8F0",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      height: "300px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      // textOverflow: "ellipsis",
      // whiteSpace: "nowrap",
      // overflow: "hidden",
      // display: state.hasValue ? "initial" : "flex",
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: isValueValid ? "#C60808" : "#8e8e8e",
      };
    },
  };

  return (
    <>
      {scope === 1 ? (
        rule_type === "total" ? (
          <InputControl
            name="value"
            label="value"
            value={value.value}
            onChange={(e) => onChange(e, index, "value")}
            error={validator.current.message("Value", value.value, "required")}
            helperText={validator.current.message(
              "Value",
              value.value,
              "required"
            )}
            required
          />
        ) : null
      ) : scope === 2 ? (
        rule_type === "customer" ? (
          <Box>
            <AsyncPaginate
              debounceTimeout={1000}
              value={value?.value ?? []}
              isMulti
              menuPosition="fixed"
              menuPlacement="top"
              placeholder="Value*"
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              multiValue={false}
              controlShouldRenderValue={false}
              components={{
                Option: CustomOption,
                MultiValueContainer: MyMultiValueContainer,
              }}
              key={JSON.stringify(selectedCustomersInOtherIndex)}
              loadOptions={async (e, p, { page }) => {
                const customerData = await handleGetCustomers({
                  search: e,
                  page,
                });
                const filteredCustomerData = {
                  ...customerData,
                  options: customerData.options.filter((op) => {
                    return !selectedCustomersInOtherIndex.find(
                      (item) => item.value === op.value
                    );
                  }),
                };

                return filteredCustomerData;
              }}
              onChange={(e) => {
                onChange(
                  {
                    target: {
                      name: "value",
                      value: e,
                    },
                  },
                  index,
                  "scope"
                );
              }}
              additional={{
                page: 1,
              }}
              styles={customStyles}
            />

            <FormHelperText sx={{ color: "#C60808" }}>
              {validator.current.message("value", value.value, "required")}
            </FormHelperText>

            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "12px",
              }}
            >
              {value?.value.length > 0 &&
                value?.value?.map((data, i) => (
                  <Chip
                    key={i}
                    label={
                      <Typography variant="body2" fontSize="12px">
                        {data.label}
                      </Typography>
                    }
                    onDelete={() => {
                      onDeleteCustomerSelect(data, i, index);
                    }}
                    sx={{
                      backgroundColor: "#F2F2F2",
                      height: "30px",
                      "& .MuiChip-deleteIcon": {
                        color: "#CCCCCC",
                      },
                    }}
                  />
                ))}
            </Stack>
          </Box>
        ) : null
      ) : scope === 3 ? (
        rule_type === "product" ? (
          <Dropdown
            value={value.value}
            onChange={onChange}
            options={productOptions}
            index={index}
            validator={validator}
            open={open}
            handleClick={handleClick}
            onDelete={onDeleteProducts}
            setInputValue={setInputValue}
            inputValue={inputValue}
          />
        ) : rule_type === "price" ? (
          <InputControl
            type="number"
            name="value"
            label="value"
            value={value.value}
            onChange={(e) => onChange(e, index, "value")}
            error={validator.current.message("Value", value, "required")}
            helperText={validator.current.message("Value", value, "required")}
            required
          />
        ) : null
      ) : null}
    </>
  );
};

const Dropdown = ({
  options,
  value = [],
  onChange,
  index,
  validator,
  open,
  handleClick,
  onDelete,
  setInputValue,
  inputValue,
}) => {
  const isSelected = (data) => {
    const hasItem =
      value.length > 0 && value.some((item) => item.value === data.value);
    return hasItem;
  };
  const isCheckIndeterminate = (option) => {
    if (option.products.length > 0 && value?.length > 0) {
      const commonIDs = value.filter((o1) =>
        option.products.some((o2) => o1.value === o2.value)
      );
      const notAllIDs = option.products.every((val) =>
        value.find((val2) => val2.value === val.value)
      );
      return commonIDs.length > 0 && !notAllIDs;
    } else {
      return false;
    }
  };

  const isSelectedCheck = (option) => {
    if (option.products.length > 0 && value?.length > 0) {
      return option.products?.every((val) =>
        value.find((val2) => val.value == val2.value)
      );
    } else {
      return false;
    }
  };
  return (
    <InputControl
      name="scope"
      type="dropdown"
      multiple
      disableCloseOnSelect
      disableClearable
      options={options || []}
      className="tags-input"
      renderOption={(props, option, { selected }) => (
        <>
          <>
            <ListItemButton
              key={index}
              sx={{
                borderBottom: "1px solid #E6E6E6",
              }}
              onClick={() => {
                handleClick(option.id);
              }}
            >
              <Checkbox
                style={{ marginRight: 8 }}
                indeterminate={isCheckIndeterminate(option)}
                checked={isSelectedCheck(option)}
                onChange={(e, checked) => {
                  onChange(
                    {
                      target: {
                        checked: checked,
                        name: "value",
                        value: option,
                      },
                    },
                    index,
                    "productHead"
                  );
                  setInputValue("");
                }}
              />
              <ListItemText
                primary={
                  <Typography
                    sx={{ display: "inline", fontWeight: 600 }}
                    component="span"
                    variant="subtitle2"
                    color="#171717"
                  >
                    {option.name} ({option.products.length})
                  </Typography>
                }
              />
              {option.products.length > 0 && (
                <>
                  <img
                    src="/icons/ic_dropdown.svg"
                    style={{
                      transform: open === option.id ? "" : "rotate(180deg)",
                    }}
                  />
                </>
              )}
            </ListItemButton>

            <Collapse
              in={open === option.id ? true : false}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {option.products.map((data, i) => (
                  <ListItem
                    key={i}
                    {...props}
                    onClick={(e) => {
                      onChange(
                        {
                          target: {
                            checked: !isSelected(data),
                            name: "value",
                            value: data,
                          },
                        },
                        index,
                        "product"
                      );
                      setInputValue("");
                    }}
                  >
                    <Checkbox
                      style={{ marginRight: 8, marginLeft: 12 }}
                      checked={isSelected(data)}
                    />
                    {data.label}
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </>
        </>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, i) => (
          <Chip
            sx={{ margin: "2px" }}
            label={option.label}
            onDelete={() => onDelete(option, index)}
          />
        ))
      }
      filterOptions={(options, state) => {
        return options.map((val) => {
          return {
            ...val,
            products: val.products.filter((val, i) => {
              return (
                val.label
                  .toString()
                  .toLowerCase()
                  .indexOf(state.inputValue.toLowerCase()) > -1
              );
            }),
          };
        });
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === "reset") {
          return;
        } else {
          setInputValue(newInputValue);
        }
      }}
      inputValue={inputValue}
      value={value || []}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Value"
          error={validator.current.message("value", value, "required")}
          helperText={validator.current.message("value", value, "required")}
          required
        />
      )}
    />
  );
};

const MyOption = (props) => {
  const { innerProps, innerRef, isSelected, data } = props;
  return (
    <Box
      sx={{ margin: "5px", height: "50px" }}
      ref={innerRef}
      {...innerProps}
      key={data.value}
    >
      <Checkbox style={{ marginRight: 8 }} checked={isSelected} />
      {data.label}
    </Box>
  );
};

const MyMultiValueContainer = ({ children, ...props }) => {
  const { data } = props;
  return <Chip sx={{ margin: "2px" }} label={data.label} />;
};
